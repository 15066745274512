import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { usePathname } from 'next/navigation'

import { textColor } from '@/utils/themeConfigs/customTheme'
import { useAuthContext } from '@/context/AuthContext'

import { DeleteModal } from './DeleteModal'
import { useFetchHistories } from '../../../hooks/useFetchHistories'
import { useDeleteHistory } from '../../../hooks/useDeleteHistory'
import { SideMenuItemWithDelete } from '@/components/layout/SideMenu/SideMenuItemWithDelete'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
`

const TitleContainer = styled(Box)`
  height: 50px;
  padding: 12px 16px 12px 24px;
`

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.448;
  color: ${textColor.dark};
`

const HistoryList = styled(Box)`
  flex-grow: 1;
  overflow-y: scroll;
`

type Props = {
  onGoToChatRoom?: () => void
}

export const History = ({ onGoToChatRoom }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  const { user } = useAuthContext()
  const token = user?.token ?? ''

  const { data: histories } = useFetchHistories(token)

  const deleteHistory = useDeleteHistory({ token })

  const pathName = usePathname()

  const handleModalOpen = (id: string) => {
    setSelectedId(id)
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleModalSubmit = () => {
    deleteHistory.mutate({ chatRoomId: selectedId })
    setIsOpen(false)
    setSelectedId('')
  }

  return (
    <Container>
      <TitleContainer>
        <Title>履歴</Title>
      </TitleContainer>
      <HistoryList>
        {histories &&
          histories.map((history) => (
            <SideMenuItemWithDelete
              key={history.id}
              id={history.id}
              text={history.chat_title}
              href={`/${history.id}`}
              onGoToChatRoom={onGoToChatRoom}
              modalOpen={handleModalOpen}
              isCurrent={pathName.includes(history.id)}
            />
          ))}
      </HistoryList>
      <DeleteModal open={isOpen} onClose={handleModalClose} submit={handleModalSubmit} />
    </Container>
  )
}
