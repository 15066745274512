import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { NextRouter, useRouter } from 'next/router'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { Layout } from '@/components/layout/Layout/Layout'
import { ChatHistory, ChatHistoryType } from '@/components/layout/Chat/ChatHistory'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useAuthContext } from '@/context/AuthContext'
import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { useInfoContext } from '@/context/InfoConrtext'
import { dateConverter } from '@/utils/helpers/convertDateFormat'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useSnackbarContext } from '@/context/SnackbarContext'
import { ChatSquareIcon } from '@/components/icon/ChatSquareIcon'
import { fontWeight, fontSize } from '@/utils/themeConfigs/customTheme'
import { useGetPrompt } from '@/features/user/prompt/hooks/useGetPrompt'

import { History } from '../ui/History/History'
import { ChatWelcome } from '../ui/chat/ChatWelcome'
import { ChatForm } from '../ui/chat/ChatForm'
import { useChatLogic } from '../../hooks/useChatLogic'
import { useLlmModel } from '../../hooks/useLlmModel'
import { useFetchChatRoom } from '../../hooks/useFetchChatRoom'
import { NewChatButton } from '../ui/chat/NewChatButton'
import { useSpeechRecognition } from '../../hooks/useSpeechRecognition'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from '@/utils/apis/api-keys'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 32px 8px;
  display: flex;
  flex-direction: column;

  ${mediaQuery('tab')} {
    padding: 0;
    height: 100%;
    min-height: 100%;
    position: relative;
  }
`

const Main = styled(Box)`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0;
  overflow: hidden;
  ${mediaQuery('tab')} {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 125px;
  }
`
const PromptInfoContainer = styled(Box)`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`

const PromptContent = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 32px 0;
  ${mediaQuery('tab')} {
    padding: 0 16px;
    margin: 16px 0 0;
  }
`

const PromptIcon = styled(Box)``

const PromptTitle = styled(Typography)`
  font-size: ${fontSize.xl};
  font-weight: ${fontWeight.bold};
`

const DialogContent = styled(Box)`
  font-size: 16px;
  font-weight: 700;
`

const ItemContainer = styled('div')`
  max-width: 358px;
  display: flex;
  gap: 18px;
  padding: 16px 0 0;
  margin: 0 auto;
`

type HandleSendChatArgumentType = {
  message?: string
  callback?: () => void
}

export type HandleSendChatType = (param?: HandleSendChatArgumentType) => void

/**
 * URLからChatRoomIdを取得する関数
 */
const getChatRoomId = ({
  router,
  pathname,
}: {
  router: NextRouter
  pathname: string
}) => {
  const { chatRoomId } = router.query
  if (typeof chatRoomId === 'string') {
    return chatRoomId
  }

  if (!pathname) return undefined

  // pathnameで渡された値から先頭と末尾の「/」を抜いた値をChatRoomIdとする
  return pathname.replace(/^\/|\/$/g, '')
}

export const Chat = () => {
  const searchParams = useSearchParams()
  const queryClient = useQueryClient()
  const promptId = searchParams.get('id') ?? ''
  const isMobile = useIsMobileOrTablet()
  const { isOpen: isMenuOpen, handleOpen, handleClose: handleMenuClose } = useDisclosure()
  const { user } = useAuthContext()
  const token = user?.token ?? ''
  const { modal, infoSummary, handleModalClose, pageTransition } = useInfoContext()

  // クエリ受け取ってChatHistoryにセットする
  const router = useRouter()
  const pathname = usePathname()
  const chatRoomId = getChatRoomId({ router, pathname })
  const { showSnackbar } = useSnackbarContext()
  const { data, error } = useFetchChatRoom({ token, chatRoomId })
  const { data: promptData, error: promptError } = useGetPrompt(
    token,
    promptId ?? (data !== undefined ? data[0].original_prompt_id : ''),
  )

  const {
    prompt,
    setPrompt,
    chatInputValue,
    handleChangeChatInput,
    isValid,
    chatHistory,
    sendChat,
    isAnswering,
    syncChatHistory,
    initializeChatHistory,
    initializeChatInput,
  } = useChatLogic()

  const {
    selectedLlmModel,
    llmModels,
    changeLlmModel,
    handleModalSubmit,
    isOpen,
    handleClose,
    changeLlmModelFromId,
    initializeLlmModelSelecting,
  } = useLlmModel({
    token,
    initializeChatHistory,
    isPromptChat: !!promptId || (data && !!data[0].original_prompt_id),
  })

  // initializeChatより後にsearchParamsが変化する
  // ここでプロンプト情報を初期化する
  useEffect(() => {
    if (!searchParams.get('id')) queryClient.removeQueries({ queryKey: QueryKeys.prompt })
  }, [searchParams])

  // プロンプト情報があればプロンプトチャットとして初期表示を設定する
  useEffect(() => {
    if (promptData && !data) {
      setPrompt({ id: promptData.prompt_id, title: promptData.prompt_name })
      if (promptData.template) initializeChatInput(promptData.template)
      if (promptData.initial_message) {
        const initHistory = [
          {
            message: promptData.initial_message,
            role: 'assistant',
          },
        ] as ChatHistoryType
        initializeChatHistory(initHistory)
      }
    } else {
      setPrompt(undefined)
      initializeChatInput()
      initializeChatHistory()
    }
  }, [promptData, data])

  // TODO: 削除済みの履歴を開こうとした際の処理
  useEffect(() => {
    if (data) {
      syncChatHistory(data)
      if (data[0].original_prompt_id && data[0].original_prompt_name) {
        setPrompt({ id: data[0].original_prompt_id, title: data[0].original_prompt_name })
      } else {
        setPrompt(undefined)
      }
      changeLlmModelFromId(data[0].llm_model_id)
      initializeChatInput()
    }
  }, [data, changeLlmModelFromId, syncChatHistory, setPrompt])

  useEffect(() => {
    if (error?.response?.status === 404) {
      void (async () => {
        await router.push('/')
      })()
    } else if (promptId && promptError?.response?.status === 400) {
      // TODO: 取得失敗のレスポンス形式を把握する
      // TODO: error?.response? がundefinedな理由を探す
      showSnackbar?.({
        text: 'プロンプトの取得に失敗しました',
        severity: 'error',
      })
      void (async () => {
        await router.push('/')
      })()
    }
  }, [error, router, promptError])

  // 音声入力などstate更新せずに送信したい時があるので送るメッセージを外から入れれるようにした
  const handleSendChat = ({ message, callback }: HandleSendChatArgumentType = {}) => {
    ;(async () => {
      // ここがundefinedになる場合は別の箇所でトーストが表示されるのでここでは処理を行わない
      if (!token || !selectedLlmModel) return

      await sendChat({
        token,
        model_id: selectedLlmModel.id,
        model_group: selectedLlmModel.group,
        chat_room_id: chatRoomId,
        original_prompt_id: prompt?.id,
        message,
      })

      callback?.()
    })().catch(() => {
      showSnackbar?.({
        text: 'エラーが発生しました。時間をおいて再度お試しください。',
        severity: 'error',
      })
    })
  }

  const { isRecording, startRecording, stopRecording } = useSpeechRecognition({
    handleSendChat,
    handleChangeChatInput,
    chatInputValue,
  })

  const initializeChat = () => {
    initializeChatHistory()

    initializeLlmModelSelecting()

    initializeChatInput()

    stopRecording()

    setPrompt(undefined)

    void (async () => {
      await router.push('/')
    })()
  }

  return (
    <>
      <Layout
        sideMenuContent={<History onGoToChatRoom={stopRecording} />}
        headerTitle='CHAT'
        mobileButton={<NewChatButton onClick={initializeChat} />}
        mobileMenu={{
          content: <History onGoToChatRoom={handleMenuClose} />,
          isMenuOpen,
          openMenu: handleOpen,
          closeMenu: handleMenuClose,
        }}
      >
        <Container>
          <Main>
            {!!prompt && (
              <PromptInfoContainer>
                <PromptContent>
                  <PromptIcon>
                    <ChatSquareIcon />
                  </PromptIcon>
                  <PromptTitle>{prompt?.title} </PromptTitle>
                </PromptContent>
              </PromptInfoContainer>
            )}
            {chatHistory.length === 0 ? (
              <ChatWelcome />
            ) : (
              <ChatHistory chatHistory={chatHistory} isAnswering={isAnswering} />
            )}
          </Main>
          <ChatForm
            handleSendChat={handleSendChat}
            chatInputValue={chatInputValue}
            handleChangeChatInput={handleChangeChatInput}
            isValid={isValid}
            changeLlmModel={changeLlmModel}
            selectedLlmModel={selectedLlmModel}
            llmModels={llmModels || []}
            isAnswering={isAnswering}
            isOpen={isOpen}
            isMobile={isMobile}
            handleCloseModal={handleClose}
            handleModalSubmit={handleModalSubmit}
            isRecording={isRecording}
            startRecording={startRecording}
            stopRecording={stopRecording}
            promptId={prompt?.id}
          />
        </Container>
      </Layout>
      {modal && (
        <BasicModal
          wrapperWidth='large'
          innerWidth='large'
          open={modal.isOpen}
          onClose={handleModalClose}
          title='新しいお知らせがあります'
        >
          <>
            <DialogContent>
              {dateConverter(infoSummary?.created_at ?? '', 'long', '.')}{' '}
              {infoSummary?.title}
            </DialogContent>
            <ItemContainer>
              <BasicButton
                onClick={handleModalClose}
                label='閉じる'
                variant='outlined'
                width='140px'
                height='47px'
              />
              <BasicButton
                label='一覧へ'
                width='140px'
                height='47px'
                onClick={pageTransition}
              />
            </ItemContainer>
          </>
        </BasicModal>
      )}
    </>
  )
}
