import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { PromptDetail } from '../types'

export const useGetPrompt = (token: string, id: string) => {
  const { apiClient } = useApiClient()

  return useQuery({
    queryKey: QueryKeys.prompt,
    queryFn: () =>
      apiClient(token)
        .get<PromptDetail>(API.prompt(id))
        .then((res) => res.data)
        .catch((err) => console.error(err)),
    enabled: !!token && !!id,
  })
}
