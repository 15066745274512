import { useMutation, useQueryClient } from '@tanstack/react-query'

import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

type DeleteHistoryResponse = {
  data: string
}

type UseDeleteHistoryArgument = {
  token: string
}

type MutationVariables = {
  chatRoomId: string
}

export const useDeleteHistory = ({ token }: UseDeleteHistoryArgument) => {
  const { apiClient } = useApiClient()
  const client = apiClient(token)
  const queryClient = useQueryClient()

  return useMutation<DeleteHistoryResponse, Error, MutationVariables>({
    mutationKey: MutationKeys.deleteHistory,
    mutationFn: async ({ chatRoomId }) => {
      return await client
        .patch<DeleteHistoryResponse>(`${API.history}/${chatRoomId}`)
        .then((res) => res.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: MutationKeys.history })
    },
  })
}
